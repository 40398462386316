.layout {
  display: grid;
  grid-template-columns: var(--grid-template-desktop);
}

/* LAYOUT LEFT */
.layout > *[data-column="first-column-blocks"]{
  display: grid;
  grid-template-columns: 1fr [main-start] repeat(6,minmax(0,var(--size-row))) [main-end];
  grid-column: full-start / 8;
}

.layout > *[data-column="first-column-blocks"] > *{
  display: grid;
  grid-template-columns: 1fr [main-start] repeat(6,minmax(0,var(--size-row))) [main-end];
  grid-column: 1 / 8;
  align-items: center;
  scroll-margin-top: var(--header-height);
}

.layout > *[data-column="first-column-blocks"] > * > *{
  grid-column: main-start / main-end;
}

.layout > *[data-column="first-column-blocks"] > *:not(:last-child) > * {
  padding-bottom: var(--spacing-24);
}

.layout > *[data-column="first-column-blocks"] > *:not(:first-child) > * {
  padding-top: 0;
}

/* LAYOUT RIGHT */
.layout > *[data-column="second-column-blocks"]{
  display: grid;
  grid-template-columns: [main-start] repeat(6,minmax(0,var(--size-row))) [main-end] 1fr;
  grid-column: 8  / full-end;
}

.layout > *[data-column="second-column-blocks"] > *{
  display: grid;
  grid-template-columns: [main-start] repeat(6,minmax(0,var(--size-row))) [main-end] 1fr;
  grid-column: 1 / full-end;
  align-items: center;
  scroll-margin-top: var(--header-height);
}

.layout > *[data-column="second-column-blocks"] > * > *{
  grid-column: main-start / main-end;
}

.layout > *[data-column="second-column-blocks"] > *:not(:last-child) > * {
  padding-bottom: var(--spacing-24);
}

.layout > *[data-column="second-column-blocks"] > *:not(:first-child) > * {
  padding-top: 0;
}


@media (max-width: 1024px) {
  .layout > *[data-column="first-column-blocks"],
  .layout > *[data-column="second-column-blocks"]{
    display: grid;
    grid-template-columns: var(--grid-template-mobile);
    grid-column: full-start / full-end;
  }
  
  .layout > *[data-column="first-column-blocks"] > *,
  .layout > *[data-column="second-column-blocks"] > *{
    display: grid;
    grid-template-columns: var(--grid-template-mobile);
    grid-column: full-start / full-end;
  }

  .layout > *[data-column="first-column-blocks"] > * > *,
  .layout > *[data-column="second-column-blocks"] > * > *{
    grid-column: main-start / main-end;
  }

  .layout > *[data-column="first-column-blocks"][data-has-image="false"] > *:last-child > * {
    padding-bottom: var(--spacing-24);
  }

  .layout > *[data-column="second-column-blocks"][data-has-image="false"] > *:last-child > * {
    padding-top: 0px;
  }
}
